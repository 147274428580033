require('../datatable.js')

$(document).on("turbolinks:load", function() {
  $('#locations__search-wrap').text("");
  $('#locations__pagination-wrap').text("");
  $('.locations-container').datatable({
    search: true,
    searchSelector: '#locations__search-wrap',
    namePlural: $('.locations-container').attr("plural"),
    nameSingular: $('.locations-container').attr("singular"),
    searchPlaceholder: "Search",
    pagingSelector: '#locations__pagination-wrap',
    rootnode: '.locations',
    isTable: true,
    elementsPerPage: 10
  });
});

$(document).on('turbolinks:click', () => {
  // Datatable-Views should not use turbolinks. This kills the datatable
  return false;
});